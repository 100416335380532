









































































































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@/utils/regex';
import {
  MultiUserFormRequest,
  TitleResponseItem,
  UpdateUserForAxios,
  UserDetailedItemResponse,
} from '@/models/users';
import moment from 'moment';
import Upload from '@/components/common/Upload.vue';
import { AcceptedImageFileType } from '@/utils/constants';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
@Component({
  components: { Dropdown, Upload },
})
export default class UserFormTemplate extends Vue {
  @Prop({ default: false })
  isEditMode!: boolean;

  @Prop()
  previousUserData!: UserDetailedItemResponse;

  @Prop()
  titles!: TitleResponseItem[];

  // General Information Column
  selectedFirstName = ``;
  selectedLastName = ``;
  selectedEmailAddress = ``;
  selectedPassword = ``;
  selectedConfirmPassword = ``;
  selectedIsForceChangePassword = false;
  selectedContactNumber = ``;
  selectedDateOfBirth: string | null | Date = null;
  selectedAddress = '';
  selectedAddress2 = '';
  selectedCity = '';
  selectedState = '';
  selectedPostcode = '';
  selectedDegree = '';
  selectedUniversity = '';

  // Profile Picture
  selectedProfilePicture: UploadAttachmentWithThumbnail = {
    originalFile: null,
    thumbnail: null,
  };
  //Edit Variable
  previousUploadedProfilePicture = ``;

  // Gender Column
  selectedGenderText = `Please Choose`;
  selectedGenderValue = ``;
  // Edit variables
  previousSelectedGender = ``;

  // Role Column
  selectedRoleText = `Please Choose`;
  selectedRoleValue = ``;
  // Edit variables
  previousSelectedRole = ``;

  // Title Column
  selectedTitleText = `Please Choose`;
  selectedTitleValue = '';
  // Edit variables
  previousSelectedTitle = '';

  // Add Button
  isButtonDisabled = true;

  // Show Error Booleans
  showFirstNameError = false;
  showLastNameError = false;
  showEmailAddressError = false;
  showPasswordError = false;
  showConfirmPasswordError = false;
  showContactNumberError = false;
  showDateOfBirthError = false;
  showRoleError = false;
  showDegreeError = false;
  showUniversityError = false;
  showGenderError = false;
  showTitleError = false;

  currentSource: UpdateUserForAxios = {
    id: -1,
    title_id: -1,
    title: {
      id: -1,
      name: '',
    },
    firstname: ``,
    lastname: ``,
    email: ``,
    password: ``,
    force_update_password: false,
    mobile: ``,
    birthday: ``,
    role: ``,
    address: ``,
    address2: ``,
    city: ``,
    state: ``,
    postcode: ``,
    degree: ``,
    university: ``,
    gender: ``,
    profile_url: ``,
  };

  // Show Error Texts
  get firstNameErrorMessage() {
    return `Invalid First Name. First name cannot be empty`;
  }
  get lastNameErrorMessage() {
    return `Invalid Last Name. Last name cannot be empty`;
  }
  get emailAddressErrorMessage() {
    return `Invalid Email Address. Please check the email format`;
  }
  get passwordErrorMessage() {
    return `Invalid Password. Password has to contain 1 capital letter and 1 special character`;
  }
  get confirmPasswordErrorMessage() {
    return `Invalid Confirm Password. This password has to be the same as Password`;
  }
  get contactNumberErrorMessage() {
    return `Invalid Contact Number. Please enter a contact number with minimum 10 numbers`;
  }
  get dateOfBirthErrorMessage() {
    return `Missing Date of Birth. Please select a Date of Birth`;
  }
  get roleErrorMessage() {
    return `Please select a role`;
  }
  get degreeErrorMessage() {
    return `Invalid Degree. Degree cannot be empty`;
  }
  get universityErrorMessage() {
    return `Invalid University. University cannot be empty`;
  }
  get genderErrorMessage() {
    return `Please select a gender`;
  }
  get titleErrorMessage() {
    return `Please select a Title`;
  }

  // Profile Picture
  get imageFileTypes() {
    return AcceptedImageFileType;
  }

  // Button Text
  get buttonText() {
    return this.$route.path === '/users/create' ? `Add` : `Save`;
  }

  get showTeacherFields() {
    return this.selectedRoleValue == 'teacher' ? true : false;
  }

  @Watch(`previousUserData`)
  repopulateForm() {
    if (this.previousUserData) {
      this.currentSource = {
        id: this.previousUserData.id,
        title_id: this.previousUserData.title_id,
        title: this.previousUserData.title,
        firstname: this.previousUserData.firstname,
        lastname: this.previousUserData.lastname,
        email: this.previousUserData.email,
        password: this.previousUserData.password,
        force_update_password: this.previousUserData.force_update_password,
        mobile: this.previousUserData.mobile,
        birthday: this.previousUserData.birthday,
        role: this.previousUserData.role,
        address: this.previousUserData.address,
        address2: this.previousUserData.address2,
        city: this.previousUserData.city,
        state: this.previousUserData.state,
        postcode: this.previousUserData.postcode,
        degree: this.previousUserData.degree,
        university: this.previousUserData.university,
        gender: this.previousUserData.gender,
        profile_url: this.previousUserData.profile_url ? this.previousUserData.profile_url : '',
      };

      this.selectedFirstName = this.currentSource.firstname;
      this.selectedLastName = this.currentSource.lastname;
      this.selectedEmailAddress = this.currentSource.email;
      this.selectedPassword = '';
      this.selectedConfirmPassword = '';
      this.selectedIsForceChangePassword = false;
      this.selectedContactNumber = this.currentSource.mobile;
      this.selectedDateOfBirth = new Date(
        moment(this.currentSource.birthday, 'DD-MM-YYYY').format('MM/DD/YYYY')
      );
      this.selectedAddress = this.currentSource.address;
      this.selectedAddress2 = this.currentSource.address2;
      this.selectedCity = this.currentSource.city;
      this.selectedState = this.currentSource.state;
      this.selectedPostcode = this.currentSource.postcode;
      this.selectedRoleValue = this.currentSource.role;
      this.previousSelectedRole = this.currentSource.role
        ? this.currentSource.role
        : this.selectedRoleValue;
      this.selectedDegree = this.currentSource.degree
        ? this.currentSource.degree
        : this.selectedDegree;
      this.selectedUniversity = this.currentSource.university
        ? this.currentSource.university
        : this.selectedUniversity;
      this.selectedGenderValue = this.currentSource.gender
        ? this.currentSource.gender
        : this.selectedGenderValue;
      this.previousSelectedGender = this.currentSource.gender
        ? this.currentSource.gender.toLowerCase()
        : this.selectedGenderValue;
      this.selectedTitleValue = this.currentSource.title?.id
        ? this.currentSource.title.id.toString()
        : this.selectedTitleValue;
      this.previousSelectedTitle = this.currentSource.title?.id
        ? this.currentSource.title?.id.toString()
        : this.selectedTitleValue;
      this.previousUploadedProfilePicture = this.previousUserData.profile_url
        ? (this.previousUserData.profile_url as string)
        : '';
    }
  }

  //TODO: Hook this to API
  get roles(): DropdownItem[] {
    return [
      {
        text: `Teacher`,
        value: `teacher`,
        uniqueKey: `teacher_1`,
      },
      {
        text: `Admin`,
        value: `admin`,
        uniqueKey: `admin_1`,
      },
    ];
  }

  get genders(): DropdownItem[] {
    return [
      {
        text: `Male`,
        value: `male`,
        uniqueKey: `male_1`,
      },
      {
        text: `Female`,
        value: `female`,
        uniqueKey: `female_1`,
      },
    ];
  }

  updateFirstName(payload: string) {
    this.selectedFirstName = payload;
    this.showFirstNameError = false;
    if (this.selectedFirstName.length === 0) {
      this.showFirstNameError = true;
    }
    this.validateForm();
  }

  updateLastName(payload: string) {
    this.selectedLastName = payload;
    this.showLastNameError = false;
    if (this.selectedLastName.length === 0) {
      this.showLastNameError = true;
    }
    this.validateForm();
  }

  updateEmailAddress(payload: string) {
    this.selectedEmailAddress = payload;
    this.showEmailAddressError = false;
    // Add Email Regex
    if (!EMAIL_REGEX.test(payload)) {
      this.showEmailAddressError = true;
    }
    this.validateForm();
  }

  updatePassword(payload: string) {
    this.selectedPassword = payload;
    this.showPasswordError = false;
    if (!PASSWORD_REGEX.test(payload)) {
      this.showPasswordError = true;
    }
    this.validateForm();
  }

  updateConfirmPassword(payload: string) {
    this.selectedConfirmPassword = payload;
    this.showConfirmPasswordError = false;
    if (!PASSWORD_REGEX.test(payload) || this.selectedPassword !== this.selectedConfirmPassword) {
      this.showConfirmPasswordError = true;
    }
    this.validateForm();
  }

  updateIsForceChangePassword(payload: boolean) {
    this.selectedIsForceChangePassword = payload;
    this.validateForm();
  }

  updateContactNumber(payload: string) {
    this.selectedContactNumber = payload;
    this.showContactNumberError = false;
    if (this.selectedContactNumber.length < 10) {
      this.showContactNumberError = true;
    }
    this.validateForm();
  }

  updateDateOfBirth(payload: string) {
    this.selectedDateOfBirth = payload;
    this.showDateOfBirthError = false;
    if (!this.selectedDateOfBirth) {
      this.showDateOfBirthError = true;
    }
    this.validateForm();
  }

  updateAddress(payload: string) {
    this.selectedAddress = payload;
    this.validateForm();
  }

  updateAddress2(payload: string) {
    this.selectedAddress2 = payload;
    this.validateForm();
  }

  updateCity(payload: string) {
    this.selectedCity = payload;
    this.validateForm();
  }

  updateState(payload: string) {
    this.selectedState = payload;
    this.validateForm();
  }

  updatePostcode(payload: string) {
    this.selectedPostcode = payload;
    this.validateForm();
  }

  updateRole(payload: DropdownItem) {
    this.selectedRoleText = payload.text;
    this.selectedRoleValue = payload.value;
    this.showRoleError = false;
    if (!payload) {
      this.showRoleError = true;
    }
    this.validateForm();
  }

  updateDegree(payload: string) {
    this.selectedDegree = payload;
    this.showDegreeError = false;
    if (this.selectedDegree.length === 0) {
      this.showDegreeError = true;
    }
    this.validateForm();
  }

  updateUniversity(payload: string) {
    this.selectedUniversity = payload;
    this.showUniversityError = false;
    if (this.selectedUniversity.length === 0) {
      this.showUniversityError = true;
    }
    this.validateForm();
  }

  updateGender(payload: DropdownItem) {
    this.selectedGenderText = payload.text;
    this.selectedGenderValue = payload.value;
    this.showGenderError = false;
    if (!payload) {
      this.showGenderError = true;
    }
    this.validateForm();
  }

  updateTitle(payload: DropdownItem) {
    this.selectedTitleText = payload.text;
    this.selectedTitleValue = payload.value;
    this.showTitleError = false;
    if (!payload) {
      this.showTitleError = true;
    }
    this.validateForm();
  }

  // Profile Picture
  updateProfilePicture(payload: UploadAttachmentWithThumbnail) {
    this.selectedProfilePicture = payload;
    this.validateForm();
  }

  validateForm() {
    this.isButtonDisabled = true;
    if (
      !this.showFirstNameError &&
      !this.showLastNameError &&
      !this.showEmailAddressError &&
      !this.showPasswordError &&
      !this.showConfirmPasswordError &&
      !this.showContactNumberError &&
      !this.showDateOfBirthError &&
      !this.showRoleError &&
      !this.showDegreeError &&
      !this.showUniversityError &&
      !this.showGenderError &&
      !this.showTitleError &&
      this.selectedFirstName.length > 0 &&
      this.selectedLastName.length > 0 &&
      this.selectedEmailAddress.length > 0 &&
      (this.selectedPassword.length > 0 || this.isEditMode) &&
      (this.selectedConfirmPassword.length > 0 || this.isEditMode) &&
      this.selectedContactNumber.length > 0 &&
      this.selectedDateOfBirth &&
      this.selectedRoleValue.length > 0 &&
      Number(this.selectedTitleValue) > 0 &&
      (this.selectedRoleValue != 'teacher' || this.selectedDegree) &&
      (this.selectedRoleValue != 'teacher' || this.selectedUniversity) &&
      (this.selectedRoleValue != 'teacher' || this.selectedGenderValue.length > 0) &&
      this.selectedProfilePicture
    ) {
      this.isButtonDisabled = false;
    }
  }

  @Emit(`submit`)
  submitForm() {
    const output: MultiUserFormRequest = {
      id: this.currentSource.id,
      title_id: Number(this.selectedTitleValue),
      firstname: this.selectedFirstName,
      lastname: this.selectedLastName,
      email: this.selectedEmailAddress,
      password: this.selectedPassword,
      force_update_password: this.selectedIsForceChangePassword,
      mobile: this.selectedContactNumber,
      birthday: moment(this.selectedDateOfBirth).format('YYYY-MM-DD'),
      role: this.selectedRoleValue,
      address: this.selectedAddress,
      address2: this.selectedAddress2,
      city: this.selectedCity,
      state: this.selectedState,
      postcode: this.selectedPostcode,
      degree: this.selectedDegree,
      university: this.selectedUniversity,
      gender: this.selectedGenderValue,
      profile_url: this.selectedProfilePicture,
    };
    return output;
  }
}
